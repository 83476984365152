/*20180727 Alvin  20181016 Rock
需要編譯至public/sass/new_design.css才能使用


千萬不要改.css 請改 .scss
*/

//@import "reset";
//@import "header"; 一個很大的專案 css應該要拆很開 目前先這樣做以圖方便

@import 'base';

//主要將內容物置中固定大小的container
.main-container{
    max-width: 1200px;
    margin: 0 auto;
}
.pull-left{
    float: left;
}
.pull-right{
    float: right;
}
//header
header{
    font-size: $standard_font_size;
    height: 50px;
    background-color: $ansforce_blue;
    color:#fff;
    text-align: center;
    text-align: -webkit-center;
    line-height: 30px;
    z-index: 10;

}
body{
    font-family: "Microsoft JhengHei","Apple LiSung Light",Arial;
    background-color:$ansforce_background;
}
//左上角icon
.head-logo{
    width: 180px;
    height: 50px;
}
.head-logo-mobile{
    width: 40px;
    height: 40px;
    margin: 5px 0 0 5px;
}

//社群區域
.social-box{    //最小寬度為160+120
    margin-top: 8px;
    div{
        //border-radius: 99px;
        width: $header_item_height;
        height: $header_item_height;
        display: inline-block;
        margin: 0 4px;
        a{
            height: $header_item_height;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}
//header 右邊的登入/語言等等
.header-sidebox{
    >div{
        margin: 10px 4px 0 4px;
        display: inline-block;
        color: #fff;
    }
    i{
        font-size: 30px;
        cursor: pointer;
        color: #fff;
    }
    button{
        padding: 0;
        margin: 10px 4px 0 4px;
        width: 60px;
        height: 30px;
        font-size: 14px;
        line-height: 19px;
        border-radius: 5px;
        border: 1px solid #fff;
        background-color: #00a1e6;
        color: #fff;
        cursor: pointer;
    }
    /* 20181018 Rock: 不再使用hicon
    .hicon img{
        width: 30px;
        height: 30px;
        margin: 10px 10px 0 10px;
        border-radius: 20px;
    }
    */
}


//下拉式選單列 目前沒用
/*
.menu-box{
    color: #666666;
    height: 50px;
    >.main-container{
        height: 50px;
        width: 100%;
    }
    .dropdown{
        font-weight: bold;
        line-height: 50px;
        text-align: center;
        font-size: $standard_font_size;
        padding: 0 $dropdown_padding;
        height: 50px;
        background-color: $ansforce_background;
        cursor: pointer;
    }
    .dropdown:hover .dropdown-menu {    //dropdown hover trigger 效果
        display: block;
     }
    .dropdown-menu{

        padding: 0;
        margin:0;
        font-size: $standard_font_size;
    }
    .dropdown-item{
        margin-left: 15%;
        height: 40px;
        padding:0;
        width: 50%;
         line-height: 40px;
    }
}
*/

//手機板的某些功能會採用上下縮放的方式
.mobile-function-box{
    z-index: 5;
    position: fixed;
    top:0;
    width: 100%;
    height: 50px;
    background-color: #e7e9ec;
    .mobile-function{
        display: none;
    }
    .mobile-function-social{
        @extend .social-box;
        margin-top: 0;
        text-align: center;
        div{
            margin: 5px 5%;
        }
    }
    .mobile-function-search{
        input{
            width: 100%;
            height: 50px;
            font-size: 14px;
        }
    }
}
//上方的左右可以滑動的功能圖片
//https://getbootstrap.com/docs/4.1/components/carousel/
.landing-box{
    .carousel-caption {
        position: absolute;
        right: 10%;
        top: 84px;
        left: 10%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
        text-align: -webkit-center;
        h1{
            //background-color: $standard_gray;
            font-size: 28px;
            //color: $ansforce_blue;
            font-weight: bold;
            line-height: 50px;
            text-shadow: 2px 2px #333;
        }
        h4{
            font-weight: bold;
            font-size: 20px;
            text-shadow: 2px 2px #333;
        }
    }
    //裡面的兩個按鈕
    .landing-button{
        button{
            margin:20px 10px;
            height: 40px;
            line-height: 20px;
            font-size: 16px;
            background-color: $ansforce_blue;
            border:none;
        }
        button:hover{
                background-color: #2e6da4;
        }
    }
}
//landing page 裡面有一些東西的區塊
/*.landing-top-banner{

}*/
/*其他畫面的landing-block*/
.landing-block{
    height: 400px;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
    margin-top: -10px;
    color: #fff;
    h1{
        color: #fff;
        font-size: 28px;
        margin: 12px 0;
        line-height: 50px;
        position: relative;
        top: 130px;
    }
    h4{
        line-height: 30px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        position: relative;
        top: 130px;
        margin: 0 auto;
    }
}
//白白的格子 裡面有一張圖和內容的 大量使用又有兩種格式 => 可以使用extend
%extend-intro{
    min-height: 200px;
    text-align: center;
    display: inline-block;
    font-size: $standard_font_size;
    padding: 0 10px;
    img{
        width: 80px;
        height: 80px;
        margin: 0 auto;
        margin-top: 10px;
    }
    >div{
        line-height: 30px;
        margin:5px 0;
        >div{
            font-weight: bold;
            padding: 5px 0;
        }
    }
}
//白白的格子
.intro-block{
    @extend %extend-intro;
}
// 上藍下黑的說明文字 寬度是最大的那種
.intro-box{
    text-align: center;
    font-size: 20px;
    line-height:40px;
    margin: 30px 0;
    //藍藍的底白白的字 很醜的副標題
    div:first-child{
        width: 100%;
        height: 60px;
        border-radius: 20px;
        color: white;
        background-color:$ansforce_blue;
        line-height: 60px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        margin: 10px 0;
    }
    p{
        margin-bottom: 0;
    }
    p:first-child{
        font-size: 28px;
        line-height:50px;
        color: $ansforce_blue;
        font-weight: bold ;
        background-color: #e4e4e4;
        padding: 5px 0;
        margin-bottom: 30px;
    }
}
//一格一格有邊框的格子 裡面又分兩格
.info-block{
    //width: calc( 25% - 22px);
    height: 280px;
    font-size: $standard_font_size;
    text-align: center;
    float: left;
    margin: 10px 0px;
    padding: 0 10px;
    >div:first-child{
        line-height: 60px;
        background-color: $ansforce_blue;
        color: white;
        font-weight: bold;
    }
    >div:nth-child(2){    //intro-block包含在這裡
        @extend %extend-intro;
        padding-top: 10px;
        min-height: 220px;
        border: solid black 1px;
        width: 100%;
    }
}
.person-block{
        margin: 20px 0;
    div{
        display:inline-block;
    }
    >div:first-child{
        background-repeat: no-repeat;
        height: 245px;
        width: 245px;
        background-image: url('/images/whitepaper/member/circle.png');
        img{
            height: 200px;
            width: 200px;
            margin:17px 10px 0 23px;
        }
    }
    >div:nth-child(2){
        width: calc( 100% - 255px );
        margin-left: 10px;
        p{
            margin: 0;
        }
        p:first-child{
            font-size: 20px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }
        p:nth-child(2){
            font-size: 16px;
            line-height: 24px;
        }
    }
}
//topic 下方寄信
.topic-mail{
    text-align: center;
    font-size: 16px;
    input{
        width: 600px;
        height: 40px;
        text-align: left;
        margin: 0 auto;
    }
    div{
        width: 600px;
        height: 40px;
        margin: 0 auto;
        text-align: left;
        line-height: 40px;
    }
    textarea{
        width: 600px;
    }
}
.info-button{
    font-size: 16px;
    color: white;
    height: 40px;
    background-color: $ansforce_blue;
    padding: 0 32px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
//白皮書部分
.landing-img{
    width: 100%;
    height: 360px;
    background-image: url('/images/whitepaper/top_banner.png');
    background-size: cover;
    padding: 40px 0 0 70px;
    &-title{
        font-size: 36px;
        font-weight: bold;
        color: $ansforce_blue;
        margin-bottom: 15px;
    }
    &-text{
        font-size: 28px;
        font-weight: bold;
    }
    img{
        width: 120px;
        margin: 20px 10px;
    }
}
.leftincn{
    width: 240px;
    margin: 0 auto !important;
    margin-top: 10px !important;
}
@media screen and (max-width: 1200px) {
    .search-box{
        //display: none;
        margin-right: 0;
    }
    .landing-box{
        .carousel-caption {
            top: 15%;
            h1{
                font-size: 22px;
            }
            h4{
                font-size: 18px;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .info-block{
        height: auto;
        div:nth-child(2){
            min-height: auto;
        }
    }
    header{
        position: fixed;
        top:0;
        width: 100%;
    }
    .header-sidebox{
        >div{
            margin: 10px 20px 0 20px;
        }
    }
    .body-container{
        margin-top: 50px;
    }
}
@media screen and (max-width: 768px) {
    .header-sidebox{
        >div{
            margin: 10px 4px 0 4px;
        }
    }
}
